<template>
  <div @click="onClickElement" :id="'table-comment-' + id" style="height: 100%">
    <div class="d-flex flex-column text-wrap" style="width: 8.5rem">
      <template v-if="text">
        <div v-if="mode === 0 && text.length > maxTextLength" v-b-popover.hover.top="text">
          {{ shortComment }}
        </div>
        <div v-else-if="mode === 0 && text.length <= maxTextLength">
          {{ shortComment }}
        </div>
      </template>
      <div v-else-if="mode === 0">-</div>
      <b-form-textarea
        v-if="mode === 1"
        v-model="text"
        debounce="300"
        :placeholder="$t('REISEN.COMMON.COMMENT')"
        rows="5"
        no-resize
        autofocus
        @update="onUpdate"
        @blur="onBlur"
      >
      </b-form-textarea>
      <b-popover
        :id="'save-comment-popup-' + id"
        :target="'table-comment-' + id"
        triggers="manual"
        placement="top"
      >
        <SavedContent :isSaved="isSaved"></SavedContent>
      </b-popover>
    </div>
  </div>
</template>

<script>
import SavedContent from '@/components/common/saved-content.vue';

export default {
  components: { SavedContent },
  props: {
    id: { type: Number },
    comment: { type: String },
    saveCommentFunction: { type: Function },
  },
  data() {
    return {
      isSaved: false,
      isLoading: true,
      text: null,
      mode: 0,
      maxTextLength: 50,
    };
  },
  watch: {
    comment: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.text = newValue;
        }
      },
    },
  },
  computed: {
    shortComment() {
      return this.text
        ? this.text.length > this.maxTextLength
          ? this.text.slice(0, this.maxTextLength) + '...'
          : this.text
        : '-';
    },
  },
  methods: {
    onBlur() {
      this.mode = 0;
    },
    onClickElement() {
      this.mode = 1;
    },
    saveComment(id, comment) {
      this.$root.$emit('bv::show::popover', 'save-comment-popup-' + this.id);
      this.isSaved = false;
      this.isLoading = true;
      comment = comment.trim();
      this.saveCommentFunction(id, comment)
        .then(() => {
          setTimeout(() => {
            this.$root.$emit('bv::hide::popover', 'save-comment-popup-' + this.id);
          }, 1000);

          this.isSaved = true;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onUpdate($event) {
      this.saveComment(this.id, $event);
    },
  },
};
</script>
<style lang="scss" scoped></style>
