<template>
  <div>
    <div
      v-for="(sortExpression, index) in sortExpressions"
      :key="'sort-expression' + sortExpression.key + index"
      class="container-fluid d-flex align-items-center mb-1"
    >
      <b-badge v-b-tooltip.hover title="Priorität der Sortierung" class="mr-1">{{ index + 1 }}</b-badge>
      <b-form-select
        size="sm"
        class="mr-1"
        @change="onSortExpressionKeyChanged($event, sortExpression, index)"
        :value="sortExpression.key"
        :options="sortOptionsWithLabels"
      ></b-form-select>
      <b-form-select
        size="sm"
        class="mr-1"
        @change="onSortExpressionOrderChanged($event, sortExpression, index)"
        :value="sortExpression.desc"
        :options="[
          { text: 'Aufsteigend', value: false },
          { text: 'Absteigend', value: true },
        ]"
      ></b-form-select>
      <b-button
        size="sm"
        title="Löschen"
        variant="danger"
        class="btn btn-light-primary btn-xs p-1"
        @click="onClickDeleteSortExpression($event, sortExpression, index)"
      >
        <b-icon-trash />
      </b-button>
    </div>
    <div class="container-fluid d-flex align-items-center justify-content-end mb-1">
      <b-button
        v-if="Array.isArray(sortExpressions) && sortExpressions.length < maxSortAmound"
        variant="primary"
        size="sm"
        @click="onClickAddSortExpression()"
      >
        <b-icon icon="plus" />
        Sortierung hinzufügen
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
    },
    sortOptions: {
      type: Array,
    },
    maxSortAmound: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      sortExpressions: [],
    };
  },
  computed: {
    sortOptionsWithLabels() {
      return this.sortOptions.map(option => {
        return {
          value: option.key,
          text: option.label,
          disabled: this.sortExpressions.some(expression => expression.key == option.key),
        };
      });
    },
  },
  watch: {
    value: {
      immediate: true,
      handler: async function (v) {
        if (v) {
          this.sortExpressions = v;
        }
      },
    },
  },
  methods: {
    onSortExpressionKeyChanged($event, item, index) {
      this.sortExpressions[index].key = $event;
      this.$emit('update', this.sortExpressions);
    },
    onSortExpressionOrderChanged($event, item, index) {
      this.sortExpressions[index].desc = $event;
      this.$emit('update', this.sortExpressions);
    },
    onClickDeleteSortExpression($event, item) {
      this.sortExpressions.splice(this.sortExpressions.indexOf(item), 1);
      this.$emit('update', this.sortExpressions);
    },
    onClickAddSortExpression() {
      this.sortExpressions.push({
        key: this.sortOptions.filter(option =>
          this.sortExpressions.every(expression => expression.key !== option.key)
        )[0].key,
        desc: true,
      });
      this.$emit('update', this.sortExpressions);
    },
  },
};
</script>
