<template>
  <div class="d-flex flex-column flex-grow-1">
    <FilterRegionCountry
      placeholder="Status"
      class="flex-column"
      @inputRegion="setRegionFilter"
      @inputCountry="setCountryFilter"
      :countriesGroupedByRegions="countriesGroupedByRegions"
      :valueRegion="$store.state[filterNamespace].filter.regionFilter"
      :valueCountry="$store.state[filterNamespace].filter.countryFilter"
    />
    <KuerzelFilter
      class="mt-4"
      @kuerzelSelected="setKuerzelFilter"
      @reiseterminkuerzelSearch="onReiseterminkuerzelSearch"
      :options="kuerzelFilterOptions"
      :value="kuerzelFilter"
      placeholder="Reise oder Reiseterminkürzel"
    />
    <DateRangePicker
      v-if="
        filterNamespace === 'overview' ||
        filterNamespace === 'reisefinder' ||
        filterNamespace === 'garantiertTable'
      "
      class="mt-4"
      placeholder="Startdatum (H-Leistung)"
      :value="$store.state[filterNamespace].filter.startDateFilter"
      @input="setStartDateFilter"
    />
    <DateRangePicker
      v-if="
        filterNamespace === 'overview' ||
        filterNamespace === 'reisefinder' ||
        filterNamespace === 'garantiertTable'
      "
      class="mt-6"
      :placeholder="$t('REISEN.COMMON.RELEASEDATE')"
      :value="$store.state[filterNamespace].filter.releaseDateFilter"
      @input="setReleaseDateFilter"
    />
    <DateRangePicker
      v-if="
        filterNamespace === 'overview' ||
        filterNamespace === 'reisefinder' ||
        filterNamespace === 'garantiertTable'
      "
      class="mt-6"
      :placeholder="$t('REISEN.COMMON.ABREISEDATE')"
      :value="$store.state[filterNamespace].filter.abreiseDateFilter"
      @input="setAbreiseDateFilter"
    />
    <FilterbarBearbeiter
      class="mt-6"
      @input="setBearbeiterFilter"
      :users="$store.state.users.users"
      :authenticatedUser="$store.state.auth.account"
      :selectedBearbeiter.sync="$store.state[filterNamespace].filter.selectedBearbeiter"
    />
    <FilterPax
      class="mt-4"
      placeholder="Ist PAX"
      @input="setPaxFilter"
      :value="$store.state[filterNamespace].filter.paxFilter"
    />
    <FilterFrist
      v-if="filterNamespace === 'teamdashboard'"
      class="mt-4"
      placeholder="Frist"
      @input="setFristFilter"
      :value="$store.state[filterNamespace].filter.fristFilter"
    />
    <FilterVorgangBlankState
      v-if="filterNamespace === 'teamdashboard'"
      class="mt-4"
      placeholder="Vorgang Blankstatus"
      :value="$store.state[filterNamespace].filter.blankStateVorgangFilter"
      @input="setBlankStateVorgangFilter"
    />
    <FilterBaseState
      class="mt-4"
      placeholder="Status"
      @input="setBaseStateFilter"
      :value="$store.state[filterNamespace].filter.baseStateFilter"
    />

    <b-form-checkbox class="mt-4" @input="setGarantiertFilter" :checked="garantiertFilter" switch size="md">
      Garantierte Termine
    </b-form-checkbox>
    <b-form-checkbox class="mt-4" @input="setIstPendingFilter" :checked="pendingFilter" switch size="md">
      Pending Termine
    </b-form-checkbox>
    <b-form-checkbox class="mt-4" @input="setIstErledigtFilter" :checked="erledigtFilter" switch size="md">
      Erledigte Termine
    </b-form-checkbox>
    <b-form-checkbox
      class="mt-4"
      @input="setIstAusgeblendetFilter"
      :checked="ausgeblendetFilter"
      switch
      size="md"
    >
      Ausgeblendete Termine
    </b-form-checkbox>
    <b-form-checkbox
      class="mt-4"
      @input="setSechserhuelsenFilter"
      :checked="sechserhuelsenFilter"
      switch
      size="md"
    >
      Vorgänge mit 6er Hülsen in Termin enthalten
    </b-form-checkbox>

    <div class="w-100 mt-4 flex-grow-1 flex-column">
      <b-button
        :disabled="isCurrentFilterApplied || isInitiallyLoading"
        lg
        block
        @click="onClickSaveFilter($event)"
        variant="primary"
      >
        Filter anwenden
      </b-button>
      <b-button
        block
        variant="danger"
        @click="onClickResetFilter($event)"
        v-show="isAnyFilterApplied"
        v-t="'REISEN.COMMON.RESET'"
      ></b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { add, format } from 'date-fns';
import {
  RESET_FILTER,
  SET_IS_CURRENT_FILTER_APPLIED,
  SAVED_FILTER,
  SET_BEARBEITER,
  SET_DEFAULT_BEARBEITER,
  SET_BASE_STATE_FILTER,
  SET_PAX_FILTER,
  SET_REISE_FILTER,
  SET_REGION_FILTER,
  SET_COUNTRY_FILTER,
  SET_START_DATE_FILTER,
  SET_RELEASE_DATE_FILTER,
  SET_ABREISE_DATE_FILTER,
  SET_FRIST_FILTER,
  SET_BLANK_STATE_VORGANG_FILTER,
  SET_IST_PENDING_FILTER,
  SET_APPLIED_FILTER,
  SET_GARANTIERT_FILTER,
  SET_REISETERMIN_KUERZEL_FILTER,
  GET_REISE_FILTER_OPTIONS,
  GET_REISETERMIN_KUERZEL_FILTER_OPTIONS,
  SET_BASIS_PREIS_FILTER,
  SET_INTERESSENTEN_FILTER,
  SET_SECHSERHUELSEN_FILTER,
  SET_HASVORGAENGEWITHFLUGTICKETSAUSGESTELLT_FILTER,
  SET_IST_AUSGEBLENDET_FILTER,
  SET_IST_ERLEDIGT_FILTER,
} from '@/core/produkte/reisetermine/stores/reisetermin-search.module';

import { GET_ALL_COUNTRIES_GROUPED_BY_REGIONS } from '@/core/produkte/reisen/stores/reisen.module';

import { buildKuerzelFilterArray, buildKuerzelFilterOptionsArray } from '@/core/common/helpers/utils';

import FilterbarBearbeiter from '@/components/common/filter-bearbeiter.vue';
import FilterRegionCountry from '@/components/produkte/reisetermine/filter-region-country.vue';
import DateRangePicker from '@/components/common/date-range-picker.vue';

import FilterPax from '@/components/produkte/reisetermine/filter-pax.vue';
import FilterFrist from '@/components/produkte/reisetermine/filter-frist.vue';
import FilterBaseState from '@/components/produkte/reisetermine/filter-base-state.vue';

import SearchReiseMultiple from '@/components/produkte/reisen/search-reise-multiple.vue';
import KuerzelFilter from '@/components/common/kuerzel-filter.vue';
import FilterVorgangBlankState from '@/components/vorgaenge/filter-vorgang-blank-state.vue';

import * as applicationInsights from '@/core/common/services/application-insights.service';

export default {
  components: {
    FilterbarBearbeiter,
    FilterRegionCountry,
    DateRangePicker,
    FilterPax,
    FilterBaseState,
    FilterVorgangBlankState,
    SearchReiseMultiple,
    FilterFrist,
    KuerzelFilter,
  },
  props: {
    filterNamespace: { type: String, required: true },
    defaultBearbeiter: { type: Array },
    selectedBearbeiter: { type: Array },
  },
  computed: {
    ...mapState({
      countriesGroupedByRegions: state => state.reisen.countriesGroupedByRegions,
    }),
    ...mapGetters(['currentUserName', 'parsedRoles']),
    isInitiallyLoading() {
      return this.$store.state[this.filterNamespace].isInitiallyLoading;
    },
    isAnyFilterApplied() {
      return this.$store.state[this.filterNamespace].isAnyFilterApplied;
    },
    pendingFilter() {
      return this.$store.state[this.filterNamespace].filter.istPendingFilter;
    },
    erledigtFilter() {
      return this.$store.state[this.filterNamespace].filter.istErledigtFilter;
    },
    isCurrentFilterApplied() {
      return this.$store.state[this.filterNamespace].isCurrentFilterApplied;
    },
    garantiertFilter() {
      return this.$store.state[this.filterNamespace].filter.garantiertFilter?.filter['garantiert'].eq;
    },
    sechserhuelsenFilter() {
      return this.$store.state[this.filterNamespace].filter.sechserhuelsenFilter;
    },
    umsatzFilter() {
      return this.$store.state[this.filterNamespace].filter.umsatzFilter;
    },
    ausgeblendetFilter() {
      return this.$store.state[this.filterNamespace].filter.istAusgeblendetFilter;
    },
    basisPreisFilter() {
      return this.$store.state[this.filterNamespace].filter.basisPreisFilter;
    },
    kuerzelFilterOptions() {
      return buildKuerzelFilterOptionsArray(
        this.$store.state[this.filterNamespace].reiseFilterOptions,
        this.$store.state[this.filterNamespace].reiseterminKuerzelFilterOptions
      );
    },
    kuerzelFilter() {
      return buildKuerzelFilterArray(
        this.$store.state[this.filterNamespace].filter.reisenFilter,
        this.$store.state[this.filterNamespace].filter.reiseterminkuerzelFilter
      );
    },
  },
  mounted() {
    // default filter for garantiert table
    if (this.filterNamespace === 'garantiertTable') {
      this.setGarantiertFilter(true);

      this.$store.commit(
        this.filterNamespace + '/' + SET_HASVORGAENGEWITHFLUGTICKETSAUSGESTELLT_FILTER,
        true
      );
      this.$store.commit(this.filterNamespace + '/' + SET_BLANK_STATE_VORGANG_FILTER, [
        {
          state: 'Buchung',
          stateType: 'BaseState',
          substateOf: null,
          groupIdentifier: null,
          roles: [],
          text: 'Buchung',
        },
        {
          state: 'BuchungReleased',
          stateType: 'BaseState',
          substateOf: null,
          groupIdentifier: null,
          roles: [],
          text: 'Buchung Released',
        },
      ]);
    }

    // if a bearbeiter is already set, commit it to the store
    if (this.defaultBearbeiter) {
      this.$store.commit(this.filterNamespace + '/' + SET_DEFAULT_BEARBEITER, this.defaultBearbeiter);
    }

    // initialize the filter store with the inital filter
    this.$store.commit(
      this.filterNamespace + '/' + SET_APPLIED_FILTER,
      this.$store.getters[this.filterNamespace + '/reiseterminFiltersObject']
    );

    if (!this.countriesGroupedByRegions) {
      this.$store.dispatch(GET_ALL_COUNTRIES_GROUPED_BY_REGIONS);
    }

    // Load the options of the state filters
    this.$store.dispatch(this.filterNamespace + '/' + GET_REISE_FILTER_OPTIONS);

    const startDateFilter = this.$store.state[this.filterNamespace].filter.startDateFilter;
    if (this.filterNamespace === 'garantiertTable') {
      const currentDate = new Date();
      const dateInOneYear = add(currentDate, { days: 365 });
      this.$store.commit(this.filterNamespace + '/' + SET_START_DATE_FILTER, {
        start: format(currentDate, 'yyyy-MM-dd'),
        end: format(dateInOneYear, 'yyyy-MM-dd'),
      });
    }
  },
  methods: {
    onReiseterminkuerzelSearch($event) {
      this.$store.dispatch(this.filterNamespace + '/' + GET_REISETERMIN_KUERZEL_FILTER_OPTIONS, {
        kuerzelPart: $event,
      });
    },
    onClickSaveFilter($event) {
      applicationInsights.trackEventWithRouteAndUser(
        { name: `Applied Filter ${this.filterNamespace}` },
        {
          roles: this.parsedRoles,
          currentUserName: this.currentUserName,
        }
      );
      this.$store.dispatch(this.filterNamespace + '/' + SAVED_FILTER);
    },
    onClickResetFilter($event) {
      this.$store.dispatch(this.filterNamespace + '/' + RESET_FILTER);
    },
    setRegionFilter(region) {
      this.$store.commit(this.filterNamespace + '/' + SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(this.filterNamespace + '/' + SET_REGION_FILTER, region);
      // Reisekuerzel filter is based on region and country selection so we have to reload it
      this.$store.dispatch(this.filterNamespace + '/' + GET_REISE_FILTER_OPTIONS);
    },
    setCountryFilter(country) {
      this.$store.commit(this.filterNamespace + '/' + SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(this.filterNamespace + '/' + SET_COUNTRY_FILTER, country);
      // Reisekuerzel filter is based on region and country selection so we have to reload it
      this.$store.dispatch(this.filterNamespace + '/' + GET_REISE_FILTER_OPTIONS);
    },
    setStartDateFilter(start, end) {
      this.$store.commit(this.filterNamespace + '/' + SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(this.filterNamespace + '/' + SET_START_DATE_FILTER, {
        start: start || null,
        end: end || null,
      });
    },
    setReleaseDateFilter(start, end) {
      this.$store.commit(this.filterNamespace + '/' + SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(this.filterNamespace + '/' + SET_RELEASE_DATE_FILTER, {
        start: start || null,
        end: end || null,
      });
    },
    setAbreiseDateFilter(start, end) {
      this.$store.commit(this.filterNamespace + '/' + SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(this.filterNamespace + '/' + SET_ABREISE_DATE_FILTER, {
        start: start || null,
        end: end || null,
      });
    },
    setBearbeiterFilter(user) {
      this.$store.commit(this.filterNamespace + '/' + SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(this.filterNamespace + '/' + SET_BEARBEITER, user);
    },
    setReiseFilter(reise) {
      this.$store.commit(this.filterNamespace + '/' + SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(this.filterNamespace + '/' + SET_REISE_FILTER, reise);
    },
    setPaxFilter(pax) {
      this.$store.commit(this.filterNamespace + '/' + SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(this.filterNamespace + '/' + SET_PAX_FILTER, pax);
    },
    setFristFilter(frist) {
      this.$store.commit(this.filterNamespace + '/' + SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(this.filterNamespace + '/' + SET_FRIST_FILTER, frist);
    },
    setBlankStateVorgangFilter(blankStateVorgang) {
      this.$store.commit(this.filterNamespace + '/' + SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(this.filterNamespace + '/' + SET_BLANK_STATE_VORGANG_FILTER, blankStateVorgang);
    },
    setBaseStateFilter(baseState) {
      this.$store.commit(this.filterNamespace + '/' + SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(this.filterNamespace + '/' + SET_BASE_STATE_FILTER, baseState);
    },
    setBasisPreisFilter(basisPreis) {
      this.$store.commit(this.filterNamespace + '/' + SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(this.filterNamespace + '/' + SET_BASIS_PREIS_FILTER, basisPreis);
    },
    setGarantiertFilter(garantiert) {
      this.$store.commit(this.filterNamespace + '/' + SET_IS_CURRENT_FILTER_APPLIED, false);
      const filter = garantiert
        ? {
            label: 'Garantiert',
            filter: { ['garantiert']: { eq: true } },
          }
        : {
            label: 'Nicht garantiert',
            filter: { ['garantiert']: { eq: false } },
          };
      this.$store.commit(this.filterNamespace + '/' + SET_GARANTIERT_FILTER, filter);
    },
    setIstAusgeblendetFilter(istAusgeblendet) {
      this.$store.commit(this.filterNamespace + '/' + SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(this.filterNamespace + '/' + SET_IST_AUSGEBLENDET_FILTER, istAusgeblendet);
    },
    setIstPendingFilter(istPending) {
      this.$store.commit(this.filterNamespace + '/' + SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(this.filterNamespace + '/' + SET_IST_PENDING_FILTER, istPending);
    },
    setIstErledigtFilter(istErledigt) {
      this.$store.commit(this.filterNamespace + '/' + SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(this.filterNamespace + '/' + SET_IST_ERLEDIGT_FILTER, istErledigt);
    },
    setKuerzelFilter(kuerzelFilterArray) {
      this.$store.commit(this.filterNamespace + '/' + SET_IS_CURRENT_FILTER_APPLIED, false);
      const kuerzelFilter = kuerzelFilterArray.filter(filter => !filter.isReisekuerzel);
      const reiseFilter = kuerzelFilterArray
        .filter(filter => filter.isReisekuerzel)
        .map(filter => ({
          id: filter.kuerzelId,
          reisekuerzel: filter.kuerzelLabel,
        }));
      this.$store.commit(this.filterNamespace + '/' + SET_REISE_FILTER, reiseFilter);
      this.$store.commit(this.filterNamespace + '/' + SET_REISETERMIN_KUERZEL_FILTER, kuerzelFilter);
    },
    setInteressentenFilter(interessenten) {
      this.$store.commit(this.filterNamespace + '/' + SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(this.filterNamespace + '/' + SET_INTERESSENTEN_FILTER, interessenten);
    },
    setSechserhuelsenFilter(sechserhuelsenFilter) {
      this.$store.commit(this.filterNamespace + '/' + SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(this.filterNamespace + '/' + SET_SECHSERHUELSEN_FILTER, sechserhuelsenFilter);
    },
  },
};
</script>
