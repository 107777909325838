<template>
  <div>
    <CustomMultiselect
      @input="$emit('reiseSelected', $event || [])"
      :value="value"
      :multiple="true"
      :limit="3"
      :limitText="limitText"
      :show-labels="false"
      :options="options"
      track-by="reisekuerzel"
      label="reisekuerzel"
      :placeholder="placeholder"
    ></CustomMultiselect>
  </div>
</template>

<script>
import CustomMultiselect from '@/components/common/custom-multiselect.vue';

export default {
  components: {
    CustomMultiselect,
  },
  props: {
    value: { type: Array },
    options: { type: Array, default: () => [] },
    placeholder: { type: String },
  },
  data() {
    return {};
  },
  methods: {
    limitText(count) {
      return `+${count} ${this.$t('REISEN.REISEN')}`;
    },
  },
};
</script>
