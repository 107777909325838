<template>
  <div>
    <CustomMultiselect
      @input="onInput"
      @search-change="onSearchChange"
      :value="value"
      :loading="isLoading"
      :multiple="true"
      :limit="3"
      :limitText="limitText"
      :show-labels="false"
      :options="options"
      track-by="kuerzelId"
      label="kuerzelLabel"
      :placeholder="placeholder"
    ></CustomMultiselect>
  </div>
</template>

<script>
import CustomMultiselect from '@/components/common/custom-multiselect.vue';

export default {
  components: {
    CustomMultiselect,
  },
  props: {
    value: { type: Array },
    options: { type: Array, default: () => [] },
    placeholder: { type: String },
    isLoading: { type: Boolean },
  },
  mounted() {},
  computed: {
    kuerzelFilterOptions() {
      return [];
    },
  },
  data() {
    return {};
  },
  methods: {
    onInput($event) {
      this.$emit('kuerzelSelected', $event || []);
    },
    onSearchChange($event) {
      if ($event.includes('_')) {
        this.$emit('reiseterminkuerzelSearch', $event);
      }
    },
    limitText(count) {
      return this.$t('REISEN.COMMON.KUERZELFILTERLIMIT', [count]);
    },
  },
};
</script>
