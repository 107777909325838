<template>
  <div>
    <ReiseterminCardVorgaengeFluege
      v-if="!isInitialLoading"
      :thwerteAtBuchungsdatumByVorgangId="thwerteAtBuchungsdatumByVorgangId"
      :isLoading="isLoading"
      :vorgaenge="vorgaengeForReisetermin"
      :passdatenCheckRequired="false"
      :dokumenteCheckRequired="false"
      @fluege-change-accepted="acceptFluegeChanged($event)"
      isGarantiertTableDetails
    />
    <b-skeleton-table v-else :rows="5" :columns="6"></b-skeleton-table>
  </div>
</template>

<script>
import ReiseterminCardVorgaengeFluege from '@/components/produkte/reisetermine/reisetermin-card-vorgaenge-fluege.vue';
import {
  GET_VORGAENGE_WITH_FLIGHTS_TEILNEHMER,
  RESET_TRANSFERPLANUNG_FLUEGE,
  GET_TH_WERTE_AT_BUCHUNGSDATUM_BY_VORGANG_ID,
} from '@/core/vorgaenge/stores/vorgaenge.module';
import { mapState } from 'vuex';

export default {
  name: 'TeamDashboardGarantiertTableDetails',
  components: { ReiseterminCardVorgaengeFluege },
  props: {
    reisetermin: { type: Object },
  },
  data() {
    return {
      vorgaengeForReisetermin: [],
    };
  },
  computed: {
    ...mapState({
      isLoading: state => state.vorgaenge.isLoading,
      isInitialLoading: state => state.vorgaenge.isInitialLoading,
      vorgaenge: state => state.vorgaenge.vorgaenge,
      thwerteAtBuchungsdatumByVorgangId: state => state.vorgaenge.thwerteAtBuchungsdatumByVorgangId,
    }),
  },
  watch: {
    vorgaenge() {
      const isVorgaengeForCurrentReisetermin = this.vorgaenge.some(vorgang =>
        vorgang.hotelleistungen.some(hotelleistung => hotelleistung.reiseterminId == this.reisetermin.id)
      );
      if (isVorgaengeForCurrentReisetermin) {
        this.vorgaengeForReisetermin = this.vorgaenge;
      }
    },
  },
  methods: {
    async acceptFluegeChanged($event) {
      await this.$store.dispatch(RESET_TRANSFERPLANUNG_FLUEGE, { id: $event.flugData.vorgangId });
    },
  },
  mounted() {
    this.$store
      .dispatch(GET_VORGAENGE_WITH_FLIGHTS_TEILNEHMER, {
        reiseterminId: this.reisetermin.id,
        filter: {
          and: [
            { ['blankState']: 'Buchung' },
            {
              flugticketAusgestellt: false,
            },
          ],
        },
      })
      .then(response => {
        this.$store.dispatch(GET_TH_WERTE_AT_BUCHUNGSDATUM_BY_VORGANG_ID, {
          reiseterminId: this.reisetermin.id,
          vorgaenge: response,
        });
      });
  },
};
</script>
<style scoped lang="scss"></style>
