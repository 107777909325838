<template>
  <div>
    <CustomMultiselect
      :value="userQuery"
      :multiple="true"
      :limit="3"
      :limitText="limitText"
      :show-labels="false"
      :options="userList"
      @input="selectUserByName"
      :placeholder="placeholder"
    ></CustomMultiselect>
  </div>
</template>

<script>
import CustomMultiselect from '@/components/common/custom-multiselect.vue';
import { parseFullUsername, isUsernameMatching } from '@/core/common/helpers/utils';
export default {
  components: {
    CustomMultiselect,
  },
  props: {
    users: {
      type: Array,
      default: () => {
        return [];
      },
    },
    value: { type: Array, default: () => [] },
    placeholder: { type: String },
  },
  methods: {
    limitText(count) {
      return `+${count} Personen`;
    },
    getSelectedUserByUsername(username) {
      return this.users.filter(user => isUsernameMatching(user, username))[0];
    },
    selectUserByName(names) {
      this.$emit('select', names?.map(this.getSelectedUserByUsername) || []);
    },
  },
  computed: {
    userQuery() {
      return this.value.filter(user => user.givenName).map(parseFullUsername);
    },
    userList() {
      return this.users.filter(user => user.givenName).map(parseFullUsername);
    },
  },
};
</script>
