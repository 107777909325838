<template>
  <div>
    <CustomMultiselect
      :value="value"
      track-by="class"
      label="label"
      :options="options"
      :placeholder="placeholder"
      :title="placeholder"
      :multiple="true"
      :show-labels="false"
      @input="$emit('input', $event || [])"
    >
      <template #tag="{ option, search, remove }">
        <span class="multiselect__tag" :class="option.class" :title="option.shortTitle"
          >{{ value.length > 2 ? option.label[0].toUpperCase() : option.shortTitle }}
          <i
            @click.stop="remove(option)"
            aria-hidden="true"
            tabindex="1"
            class="ml-2 multiselect__tag-icon"
          ></i>
        </span>
      </template>
      <template #option="{ option, search }">
        <span class="label label-inline text-white font-weight-lighter mr-2" :class="option.class">{{
          option.shortTitle
        }}</span>
        <div class="text-wrap font-weight-light ml-2">{{ option.descr }}</div>
      </template>
    </CustomMultiselect>
  </div>
</template>

<script>
import CustomMultiselect from '@/components/common/custom-multiselect.vue';
export default {
  components: {
    CustomMultiselect,
  },
  props: {
    placeholder: { type: String },
    value: { type: Array },
  },
  data() {
    return {
      options: [
        {
          descr: 'Ist-PAX = 0',
          shortTitle: '=0',
          label: 'grau',
          class: 'gray',
          filter: { ['pax/ist']: { eq: 0 } },
        },
        {
          descr: 'Ist-PAX > 0',
          shortTitle: '>0',
          label: 'blau',
          class: 'bg-primary',
          filter: { ['pax/ist']: { gt: 0 } },
        },
        {
          descr: 'Ist-PAX = Max-PAX',
          shortTitle: 'Ist=Max',
          label: 'paxIstMax',
          class: 'paxIstMax',
          filter: { ['pax/ist']: { eq: { type: 'guid', value: 'pax/max' } } },
        },
        {
          descr: 'Ist-PAX < MTN-PAX',
          shortTitle: '<MTN-PAX',
          label: 'rot',
          class: 'red',
          filter: {
            and: [{ ['pax/ist']: { lt: { type: 'guid', value: 'pax/min' } } }, { ['pax/ist']: { gt: 0 } }],
          },
        },
        {
          descr: 'Ist-PAX ≥ MTN-PAX',
          shortTitle: '≥MTN-PAX',
          label: 'lila',
          class: 'purple',
          filter: {
            and: [{ ['pax/ist']: { ge: { type: 'guid', value: 'pax/min' } } }],
          },
        },
        {
          descr: 'Ist-PAX = MTN-1',
          shortTitle: '=MTN-1',
          label: 'paxMtn-1',
          class: 'paxMtn-1',
          filter: {
            and: [
              { ['pax/min']: { eq: { type: 'guid', value: 'pax/ist add 1' } } },
              { ['pax/ist']: { gt: 0 } },
            ],
          },
        },
        {
          descr: 'Ist-PAX = MTN-2',
          shortTitle: '=MTN-2',
          label: 'paxMtn-2',
          class: 'paxMtn-2',
          filter: {
            and: [
              { ['pax/min']: { eq: { type: 'guid', value: 'pax/ist add 2' } } },
              { ['pax/ist']: { gt: 0 } },
            ],
          },
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.bootstrap.scss';
.multiselect--disabled {
  background: none;
}

.paxMtn-1 {
  background-color: #002fff !important;
}
.paxMtn-2 {
  background-color: #9f2b68 !important;
}
.paxIstMax {
  background-color: #8a08bd !important;
}
</style>
