<template>
  <div>
    <CustomMultiselect
      v-model="selected"
      :options="vorgangBlankStates"
      :loading="isLoading"
      :multiple="true"
      :close-on-select="true"
      :group-select="true"
      :placeholder="placeholder"
      :title="placeholder"
      :showLabels="false"
      track-by="state"
      label="label"
      ref="myMultiSelect"
      @input="$emit('input', $event || [])"
    >
      <span slot="noResult">Nichts gefunden.</span>
      <template slot="option" slot-scope="props">
        <div
          class="text-wrap font-weight-light ml-2"
          :class="props.option.$groupLabel ? 'font-weight-bold' : ''"
        >
          {{ props.option.label }}
        </div>
      </template>
    </CustomMultiselect>
  </div>
</template>
<script>
import CustomMultiselect from '@/components/common/custom-multiselect.vue';

import { mapState } from 'vuex';

export default {
  components: { CustomMultiselect },
  props: ['value', 'placeholder'],
  mounted() {
    this.selected = this.value;
  },
  data() {
    return {
      selected: null,
    };
  },
  watch: {
    value(v) {
      this.selected = v === null ? [] : v;
    },
  },
  computed: {
    ...mapState({
      isLoading: state => state.stateConfig.isLoading,
      vorgangBlankStates: state => state.stateConfig.vorgangBlankStates || [],
    }),
  },
};
</script>
