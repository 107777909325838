<template>
  <div>
    <CustomMultiselect
      :value="value"
      track-by="class"
      label="label"
      :options="options"
      :placeholder="placeholder"
      :title="placeholder"
      :multiple="true"
      :show-labels="false"
      @input="$emit('input', $event || [])"
    >
      <template slot="tag" slot-scope="{ option, remove }">
        <span
          :title="option.shortTitle + ' (' + option.descr + ')'"
          class="multiselect__tag"
          :class="option.class"
          >{{ value.length > 2 ? option.label[0].toUpperCase() : option.label }}
          <i @click.stop="remove(option)" aria-hidden="true" tabindex="1" class="multiselect__tag-icon"></i>
        </span>
      </template>
      <template slot="option" slot-scope="props">
        <span class="label label-inline text-white font-weight-lighter mr-2" :class="props.option.class">{{
          props.option.shortTitle
        }}</span>
        <div class="text-wrap font-weight-light ml-2">{{ props.option.descr }}</div>
      </template>
    </CustomMultiselect>
  </div>
</template>

<script>
import CustomMultiselect from '@/components/common/custom-multiselect.vue';
export default {
  components: {
    CustomMultiselect,
  },
  props: {
    placeholder: { type: String },
    value: { type: Array },
  },
  data() {
    return {
      options: [
        {
          descr: 'Frist < 2',
          shortTitle: '<2',
          class: 'red',
          label: '<2',
          filter: { ['reiseterminWorkflowFrist/frist']: { le: 2 } },
        },
        {
          descr: '3 <= Frist <= 7',
          shortTitle: '3 <= X <= 7',
          class: 'yellow',
          label: '3 <= X <= 7',

          filter: {
            and: [
              { ['reiseterminWorkflowFrist/frist']: { ge: 3 } },
              { ['reiseterminWorkflowFrist/frist']: { le: 7 } },
            ],
          },
        },
        {
          descr: 'Frist >= 8',
          shortTitle: '>=8',
          class: 'green',
          label: '>=8',
          filter: { ['reiseterminWorkflowFrist/frist']: { ge: 8 } },
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.bootstrap.scss';
.multiselect--disabled {
  background: none;
}
</style>
