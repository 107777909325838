<template>
  <div>
    <FilterOverview
      title="Garantierte Reisetermine"
      :isInitiallyLoading="isInitiallyLoading"
      @reload="onReload"
      :showFilterButton="true"
    >
      <template #toolbar-left></template>

      <template #filter>
        <ReiseterminSearchFilter filterNamespace="garantiertTable" />
      </template>
      <template #table="{ tableHeight }">
        <TeamDashboardGarantiertTable
          :isLoading="isBusy"
          :items="reisetermine"
          :allItemsCount="count"
          :role="'Fom'"
          :tableHeight="tableHeight"
          @visbilityUpdated="onVisbilityUpdated"
          @pageChange="onPageChange"
          @sortChange="onSortChange"
        />
      </template>
    </FilterOverview>
  </div>
</template>
<style lang="scss" scoped>
.card-filter-nav {
  overflow-y: auto;
}
</style>
<script>
import {
  GET_REISETERMINE,
  REISETERMINE_SORT_CHANGED,
  REISETERMINE_PAGE_CHANGED,
} from '@/core/produkte/reisetermine/stores/reisetermin-search.module';
import TeamDashboardGarantiertTable from '@/components/produkte/reisetermine/team-dashboard-tabs-garantiert-table.vue';
import ReiseterminSearchFilter from '@/components/produkte/reisetermine/reisetermin-search-filter.vue';
import FilterOverview from '@/components/common/filter-overview.vue';
import { mapState } from 'vuex';

export default {
  name: 'TeamDashboardGarantiert',
  components: {
    TeamDashboardGarantiertTable,
    ReiseterminSearchFilter,
    FilterOverview,
  },
  props: {
    title: { type: String, default: 'Prozesse' },
  },
  data() {
    return {
      domTeamdashboardMetadata: null,
      isMetadataLoading: true,
      filterHeight: 'auto',
    };
  },
  computed: {
    ...mapState('garantiertTable', ['isBusy', 'reisetermine', 'count', 'isInitiallyLoading']),
    ...mapState({
      isVisibleReiseterminModal: state => state.workflowManagerReisetermin.isVisible,
      workflowReisetermin: state => state.workflowManagerReisetermin.reisetermin,
      workflowReiseterminNeedsReload: state => state.workflowManagerReisetermin.needsReload,
    }),
  },
  async mounted() {
    this.$store.dispatch('garantiertTable/' + GET_REISETERMINE);
  },
  methods: {
    onVisbilityUpdated() {
      this.$store.dispatch('garantiertTable/' + GET_REISETERMINE);
    },
    onReload() {
      this.$store.dispatch('garantiertTable/' + GET_REISETERMINE);
    },
    onSortChange(sortExpressions) {
      this.$store.dispatch('garantiertTable/' + REISETERMINE_SORT_CHANGED, {
        sortExpressions: sortExpressions,
      });
    },
    onPageChange(ctx) {
      this.$store.dispatch('garantiertTable/' + REISETERMINE_PAGE_CHANGED, { pageContext: ctx });
    },
  },
  beforeRouteEnter(to, from, next) {
    document.title = 'FOM / Garantierte Reisetermine';
    next();
  },
  beforeRouteUpdate(to, from, next) {
    document.title = 'FOM / Garantierte Reisetermine';
    next();
  },
};
</script>
