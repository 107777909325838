<template>
  <div>
    <div class="d-xl-flex d-lg-block flex-xl-nowrap flex-lg-wrap align-items-center">
      <SearchUserMultiple
        :value="selectedBearbeiter"
        :users="users"
        @select="setBearbeiterFilter($event)"
        :placeholder="'Bearbeiter'"
        :title="'Bearbeiter'"
      ></SearchUserMultiple>
      <div
        class="d-flex flex-nowrap nav nav-light-primary nav-bold nav-pills mx-2 mt-xl-0 mt-lg-2"
        role="tablist"
      >
        <a
          style="cursor: pointer"
          class="nav-link p-1"
          :class="selectedBearbeiter.length === 0 ? 'active' : ''"
          data-toggle="dashboard-todo-filter"
          @click="setBearbeiterFilter([])"
        >
          <span>{{ $t('REISEN.COMMON.ALL') }}</span>
        </a>
        <a
          style="cursor: pointer"
          class="nav-link p-1"
          :class="
            selectedBearbeiter.length === 1 &&
            selectedBearbeiter.find(user => user.id === authenticatedUser.localAccountId)
              ? 'active'
              : ''
          "
          data-toggle="dashboard-todo-filter"
          @click="
            setBearbeiterFilter([users.filter(user => user.id === authenticatedUser.localAccountId)[0]])
          "
        >
          <span>{{ $t('COMMON.ICH') }}</span>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import SearchUserMultiple from '@/components/common/search-user-multiple.vue';
export default {
  components: {
    SearchUserMultiple,
  },
  props: {
    authenticatedUser: { required: true, type: Object },
    users: { required: true, type: Array },
    selectedBearbeiter: { type: Array },
  },
  methods: {
    setBearbeiterFilter(selectedUser) {
      if (!selectedUser[0]) {
        this.$emit('input', selectedUser);
      } else {
        this.$emit('input', selectedUser);
      }
    },
  },
};
</script>
