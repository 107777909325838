<template>
  <div>
    <CustomMultiselect
      :value="value"
      track-by="title"
      label="title"
      :options="reiseterminStates || []"
      :placeholder="placeholder"
      :title="placeholder"
      :show-labels="false"
      :multiple="true"
      @input="$emit('input', $event || [])"
    >
      <template #tag="{ option, remove }">
        <span :title="option.title" class="multiselect__tag">
          {{ value.length > 2 ? option.title.substring(0, 3) + '...' : option.title }}
          <i @click.stop="remove(option)" aria-hidden="true" tabindex="1" class="multiselect__tag-icon"></i>
        </span>
      </template>
      <template #option="{ option }">
        <span class="badge badge-secondary">{{ option.title }}</span>
      </template>
    </CustomMultiselect>
  </div>
</template>

<script>
import CustomMultiselect from '@/components/common/custom-multiselect.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    CustomMultiselect,
  },
  props: {
    placeholder: { type: String },
    value: { type: Array },
  },
  data() {
    return {
      reiseterminStates: [
        { title: 'Aufgelegt', state: 'Aufgelegt', filter: { state: 'Aufgelegt' } },
        { title: 'Abgesagt', state: 'Abgesagt', filter: { state: 'Abgesagt' } },
        { title: 'Durchführung', state: 'Durchfuehrung', filter: { state: 'Durchfuehrung' } },
        { title: 'Durchgeführt', state: 'Durchgefuehrt', filter: { state: 'Durchgefuehrt' } },
        { title: 'CM bestätigt', state: 'CmBestaetigt', filter: { state: 'CmBestaetigt' } },
        { title: 'CM angefragt', state: 'CmAngefragt', filter: { state: 'CmAngefragt' } },
        { title: 'Vorgeschlagen', state: 'Vorgeschlagen', filter: { state: 'Vorgeschlagen' } },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.bootstrap.scss';
.multiselect--disabled {
  background: none;
}
</style>
